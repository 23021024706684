(function handleModals() {
  let openModalButtons = document.querySelectorAll('[data-modal]');
  for (let openModalButton of openModalButtons) {
    openModalButton.addEventListener('click', () => {
      let target = openModalButton.dataset.modal;
      getModal(target);
    });
  }

  let closeModalButtons = document.querySelectorAll('.modal__close');
  for (let closeButton of closeModalButtons) {
    closeButton.addEventListener('click', () => {
      let target = closeButton.dataset.close;
      let modal = document.getElementById(target);
      closeModal(modal);
    });
  }

  document.addEventListener('keydown', (event) => {
    handleKeyboard(event.key);
  });

  document.addEventListener('click', (event) => {
    handleOutsideClick(event);
  });
})();

const getModal = (target) => {
  let modal = document.getElementById(target);
  setTimeout(() => {
    openModal(modal, target);
    setFocus(modal);
  }, 0);
};

const mainContent = document.getElementById('main-content');

function handleKeyboard(key) {
  switch (key) {
    case 'Escape':
    case 'Esc':
      closeModal();
      break;
    default:
      break;
  }
}

function handleOutsideClick(event) {
  let modal = document.querySelector('.show-modal');
  if (modal && !event.target.closest('.modal__content')) {
    closeModal(modal);
  }
}

function closeModal(modal) {
  let currentModal = modal || document.querySelector('.show-modal');
  if (currentModal) {
    currentModal.classList.remove('show-modal');
    mainContent.classList.remove('showing-modal');
    destroyKeyboardTrap(currentModal);

    // PET countdown interval
    if (window.countdownInterval) {
      clearInterval(window.countdownInterval);
    }
  }
}

function openModal(modal, target) {
  if (modal) {
    modal.classList.add('show-modal');
    mainContent.classList.add('showing-modal');
    if (target) {
      window.location.hash = target;
    }
    setKeyboardTrap(modal);
  }
}

function setFocus(modal) {
  if (!modal) {
    return;
  }
  let firstInput = modal.querySelector('input:not([type=hidden])');
  if (firstInput) {
    setTimeout(() => {
      firstInput.focus();
    }, 50);
  }
}

let focusedNodeBeforeModal;
let focusableElements = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';

function setKeyboardTrap(modal) {
  focusedNodeBeforeModal = document.activeElement;
  modal.addEventListener('keydown', trapModalFocus);
}

function trapModalFocus(event) {
  if (event.key !== 'Tab' && !event.shiftKey) {
    return;
  }
  let focusableNodes = this.querySelectorAll(focusableElements);
  let firstFocusableNode = focusableNodes[0];
  let lastFocusableNode = focusableNodes[focusableNodes.length - 1];
  function handleBackwardTab() {
    if (document.activeElement === firstFocusableNode) {
      event.preventDefault();
      lastFocusableNode.focus();
    }
  }
  function handleForwardTab() {
    if (document.activeElement === lastFocusableNode) {
      event.preventDefault();
      firstFocusableNode.focus();
    }
  }
  if (focusableNodes.length === 1) {
    event.preventDefault();
  }
  if (event.shiftKey) {
    handleBackwardTab();
  } else {
    handleForwardTab();
  }
}

function destroyKeyboardTrap(modal) {
  focusedNodeBeforeModal.focus();
  modal.removeEventListener('keydown', trapModalFocus);
}

function openHomePopup() {
  let modal = document.getElementById('home-popup');
  openModal(modal);
}

if (window.location.pathname === '/') {
  openHomePopup();
}

if (window.location.hash) {
  getModal(window.location.hash.substring(1));
}
