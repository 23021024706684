import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
gsap.registerPlugin(MotionPathPlugin);

const circleAnim = gsap.timeline({
  //paused:true,
  repeat: -1,
  yoyo: true
});

circleAnim.set(
  '.circle-grad stop, .circle-grad-2 stop, .circle-grad-3 stop, .circle-grad-4 stop, .circle-grad-5 stop, .circle-grad-6 stop',
  { stopColor: '#EED7EF' }
)

  // Circle 1
  .add('start')
  .to(
    '.circle-grad stop',
    {
      stopColor: gsap.utils.wrap([
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
      ]),
      duration: 1.5,
    },
    'start'
  )
  .add('middle')
  .to(
    '.circle-grad stop',
    {
      stopColor: gsap.utils.wrap([
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
      ]),
      duration: 1.5,
    },
    'middle'
  )
  .add('end')
  .to(
    '.circle-grad stop',
    {
      stopColor: gsap.utils.wrap([
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
      ]),
      duration: 1.5,
    },
    'end'
  )

  // Circle 2
  .to(
    '.circle-grad-2 stop',
    {
      stopColor: gsap.utils.wrap([
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
      ]),
      duration: 1.5,
    },
    'start'
  )
  .to(
    '.circle-grad-2 stop',
    {
      stopColor: gsap.utils.wrap([
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
      ]),
      duration: 1.5,
    },
    'middle'
  )
  .to(
    '.circle-grad-2 stop',
    {
      stopColor: gsap.utils.wrap([
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
      ]),
      duration: 1.5,
    },
    'end'
  )

  // Circle 3
  .to(
    '.circle-grad-3 stop',
    {
      stopColor: gsap.utils.wrap([
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
      ]),
      duration: 1.5,
    },
    'start'
  )
  .to(
    '.circle-grad-3 stop',
    {
      stopColor: gsap.utils.wrap([
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
      ]),
      duration: 1.5,
    },
    'middle'
  )
  .to(
    '.circle-grad-3 stop',
    {
      stopColor: gsap.utils.wrap([
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
      ]),
      duration: 1.5,
    },
    'end'
  )

  // Circle 4
  .to(
    '.circle-grad-4 stop',
    {
      stopColor: gsap.utils.wrap([
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
      ]),
      duration: 1.5,
    },
    'start'
  )
  .to(
    '.circle-grad-4 stop',
    {
      stopColor: gsap.utils.wrap([
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
      ]),
      duration: 1.5,
    },
    'middle'
  )
  .to(
    '.circle-grad-4 stop',
    {
      stopColor: gsap.utils.wrap([
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
      ]),
      duration: 1.5,
    },
    'end'
  )

  // Circle 5
  .to(
    '.circle-grad-5 stop',
    {
      stopColor: gsap.utils.wrap([
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
      ]),
      duration: 1.5,
    },
    'start'
  )
  .to(
    '.circle-grad-5 stop',
    {
      stopColor: gsap.utils.wrap([
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
      ]),
      duration: 1.5,
    },
    'middle'
  )
  .to(
    '.circle-grad-5 stop',
    {
      stopColor: gsap.utils.wrap([
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
      ]),
      duration: 1.5,
    },
    'end'
  )

  // Circle 6
  .to(
    '.circle-grad-6 stop',
    {
      stopColor: gsap.utils.wrap([
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
      ]),
      duration: 1.5,
    },
    'start'
  )
  .to(
    '.circle-grad-6 stop',
    {
      stopColor: gsap.utils.wrap([
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
        '#C2ACD4',
        '#A1AEDE',
      ]),
      duration: 1.5,
    },
    'middle'
  )
  .to(
    '.circle-grad-6 stop',
    {
      stopColor: gsap.utils.wrap([
        '#C2ACD4',
        '#A1AEDE',
        '#A1AEDE',
        '#EED7EF',
        '#CFACD1',
      ]),
      duration: 1.5,
    },
    'end'
  );

circleAnim.pause();

export const clearCirlces = () => {
  circleAnim.time(0);
  circleAnim.pause();
};

export const startCircles = () => {
  circleAnim.restart();
};
