import throttle from 'lodash/throttle';
let menuButton = document.getElementById('menu-button');
let menuContainer = document.getElementById('menu');
let menuIcon = document.querySelector('.menu__button-icon');
let header = document.querySelector('.header');
let menuButtonText = document.querySelector('.menu__button-text');
let menuItemsSubmenu = document.querySelectorAll('.menu__item--with-submenu');

if (menuButton) {
  menuButton.addEventListener('click', () => {
    menuContainer.classList.toggle('show-menu');
    menuIcon.classList.toggle('menu__button-icon--close');
    if (header) {
      header.classList.add('header--opaque');
    }
    if (menuButtonText) {
      menuButtonText.classList.toggle('hidden');
    }

    let menuItemOpen = document.querySelector('.menu__item--open');
    if (menuItemOpen) {
      menuItemOpen.classList.remove('menu__item--open');
    }
  });

}

let scrollPos = window.scrollY;
let headerHeight;
if (header) {
  headerHeight = header.offsetHeight;
  window.addEventListener('scroll', throttle(addMenuClass, 100, { trailing: true }));
}


function addMenuClass() {
  scrollPos = window.scrollY;
  if (menuContainer.classList.contains('show-menu')) {
    return;
  }
  header.classList.toggle('header--opaque', scrollPos > headerHeight - 20);
}


menuItemsSubmenu.forEach((item) => {
  item.addEventListener('click', () => {
    let menuItemOpen = document.querySelector('.menu__item--open');

    if (menuItemOpen) {
      menuItemOpen.classList.remove('menu__item--open');
    }
    if (menuItemOpen === item) {
      return;
    }

    item.classList.add('menu__item--open');
  });
});
