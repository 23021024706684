(function handleSharingLinks() {
  let sharingLink = document.querySelectorAll('.share__open-new-window');
  if (sharingLink.length) {
    sharingLink.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        window.open(this.href, 'targetWindow', 'scrollbars=yes,resizable=yes,width=480,height=520');
        return false;
      });
    });
  }
})();
