import { startLock, clearLock } from './lock';
import { startLines, clearLines } from './lines';
import { startCircles, clearCirlces } from './circles';
import { startBody, clearBody } from './lockBody';

export const clearCompliance = () => {
  clearLock();
  clearLines();
  clearCirlces();
  clearBody();
};

export const startCompliance = () => {
  startLines();
  startLock(startContinuousAnimations);
};

const startContinuousAnimations = () => {
  startCircles();
  startBody();
};
