import { gsap } from 'gsap';
  
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';

gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,MotionPathPlugin);

const centerLine = '.timeline__line';

gsap.set(centerLine, {y: 200, opacity:0});
gsap.to(centerLine, {
    scrollTrigger: {
        trigger: '.timeline',
        start: 'top center',
        end:'10% center',
        toggleActions: 'play complete none none',
        scrub: 1
        },
        y: 0,
        opacity: 1,
        duration: 1
});

const timelineYears = gsap.utils.toArray('.timeline__year');

timelineYears.forEach(timelineYear => {
    gsap.set(timelineYear, {y: 100, opacity:0})
    gsap.to(
        timelineYear, {
            scrollTrigger: {
                trigger: timelineYear,
                start: '-60% center',
                end: '100% center',
                toggleActions: 'play complete none none',
                scrub: 1
            },
            y: 0,
            opacity: 1,
            duration: 1
        }
    )
});


const timelineItems = gsap.utils.toArray('.timeline__item');

timelineItems.forEach(timelineItem => {
    gsap.set(timelineItem, {opacity: 0})
    gsap.to(
        timelineItem, {
            scrollTrigger: {
                trigger: timelineItem,
                start: '-20% center',
                end: '100% center',
                toggleActions: 'play complete none none',
                scrub: 1
            },
            opacity: 1,
            duration: 0.5
        }
    )
});

