import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
gsap.registerPlugin(MotionPathPlugin);

const lockTopAnima = gsap.timeline({
  repeat: 0,
  defaults: {
    duration: 2,
  },
});

lockTopAnima.set('.lock-top', {
  translateY: 0,
})
  .to('.lock-top', {
    translateY: 0,
    transformOrigin: 'right bottom'
  })
  .to('.lock-top', {
    translateY: 17,
    transformOrigin: 'right bottom'
  });

lockTopAnima.pause();

export const clearLock = () => {
  lockTopAnima.time(0);
  lockTopAnima.pause();
};

export const startLock = (callback) => {
  lockTopAnima.restart(true);
  lockTopAnima.eventCallback('onComplete', callback);
};
