let copyButton = document.querySelector('.copy-to-clipboard');
if (copyButton) {
  copyButton.addEventListener('click', function () {
    if (!navigator.clipboard) {
      return;
    }
    let text = this.dataset.text;
    let originalIcon = this.querySelector('.copy-link-icon');
    let successIcon = this.querySelector('.copied--success');
    let errorIcon = this.querySelector('.copied--error');
    navigator.clipboard.writeText(text).then(() => {
      toggleClasses(originalIcon, successIcon);
    }, () => {
      toggleClasses(originalIcon, errorIcon);
    });
  });
}

function toggleClasses(originalIcon, resultIcon) {
  originalIcon.classList.add('copied--hidden');
  resultIcon.classList.remove('copied--hidden');
  setTimeout(() => {
    resultIcon.classList.add('copied--hidden');
    originalIcon.classList.remove('copied--hidden');
  }, 50000);
}
