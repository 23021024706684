import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
gsap.registerPlugin(MotionPathPlugin);

gsap.set('.medium-rect, .big-rect, .massive-rect', { transformOrigin: 'left bottom' });

const rectTimeline = gsap.timeline({
  repeat: 0
})
  .to('.small-rect', {
    opacity: 1,
    duration: 1
  })
  .from('.medium-rect', {
    opacity: 0,
    duration: 2,
    scale: 0.7
  }, '>')
  .to('.medium-rect', {
    opacity: 1,
    duration: 1,
    scale: 1
  })
  .from('.big-rect', {
    opacity: 0,
    duration: 2,
    scale: 0.7
  }, '<-0.8')
  .to('.big-rect', {
    opacity: 1,
    duration: 1,
    scale: 1
  })
  .from('.massive-rect', {
    opacity: 0,
    duration: 2,
    scale: 0.7
  }, '<-1')
  .to('.massive-rect', {
    opacity: 1,
    duration: 1,
    scale: 1
  });

rectTimeline.pause();

export const clearRectangles = () => {
  rectTimeline.time(0);
  rectTimeline.pause();
};

export const startRectangles = () => {
  rectTimeline.restart();
};
