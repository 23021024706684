import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
gsap.registerPlugin(MotionPathPlugin);

// gsap.set('#line-mark-1', { opacity: 0 });
gsap.to('#line-mark-2', { opacity: 0 });
gsap.to('#line-mark-3', { opacity: 0 });
gsap.to('#line-mark-4', { opacity: 0 });

const linesTimeline = gsap.timeline({
  repeat: 0
})
  .to('#line-mark-1', {
    opacity: 1,
    duration: 1
  })
  .from('#line-mark-2', {
    opacity: 0,
    duration: 2
  }, '>')
  .to('#line-mark-2', {
    opacity: 1,
    duration: 1
  })
  .from('#line-mark-3', {
    opacity: 0,
    duration: 2
  }, '<-0.8')
  .to('#line-mark-3', {
    opacity: 1,
    duration: 1
  })
  .add('here')
  .from('#line-mark-4', {
    opacity: 0,
    duration: 2
  }, '<-0.8')
  .to('#line-mark-4', {
    opacity: 1,
    duration: 1
  },)

  //The line-base animation
  .to('.line-base stop', {
    stopColor: gsap.utils.wrap(['#D0AED2', '#D0AED2', '#302D4A', '#302D4A', '#302D4A', '#302D4A']),
    duration: 0.8
  }, 'here-=5')
  .to('.line-base stop', {
    stopColor: gsap.utils.wrap(['#302D4A', '#D0AED2', '#D0AED2', '#302D4A', '#302D4A', '#302D4A']),
    duration: 0.8
  }, '>')
  .to('.line-base stop', {
    stopColor: gsap.utils.wrap(['#302D4A', '#302D4A', '#D0AED2', '#D0AED2', '#302D4A', '#302D4A']),
    duration: 0.8
  }, '>')
  .to('.line-base stop', {
    stopColor: gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#302D4A', '#D0AED2', '#D0AED2']),
    duration: 0.8
  }, '>')
  .to('.line-base stop', {
    stopColor: gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#D0AED2']),
    duration: 0.8
  }, '>')
  .to('.line-base stop', {
    stopColor: gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A']),
    duration: 0.6
  }, '>');


linesTimeline.pause();

export const clearLines = () => {
  linesTimeline.time(0);
  linesTimeline.pause();
};

export const startLines = () => {
  linesTimeline.restart();
};
