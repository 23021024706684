import { gsap } from 'gsap';

(function () {
  const accordion = document.querySelector('.accordion');
  const animations = [];

  if (accordion) {
    const accordionPanels = gsap.utils.toArray('.accordion-panel');
    accordionPanels.forEach(panel => {
      createAnimation(panel);
      const panelLabel = panel.querySelector('.accordion-panel__button');
      const panelContent = panel.querySelector('.accordion-panel__content');
      panel.addEventListener('click', () => {
        runAnimation(panel, panelLabel, panelContent);
      });
    });
    // TODO: fix bug, when resizing the window the accordion doesn't update its height
  }

  function runAnimation(panel, panelButton, panelContent) {
    const open = panelButton.animation.reversed();
    animations.forEach(animation => {
      animation.reverse();
    });

    const openedPanel = accordion.querySelector('.accordion-panel--expanded');

    if (openedPanel) {
      const openedContent = openedPanel.querySelector('.accordion-panel__content');
      const showMoreButton = openedPanel.querySelector('.button--showing');
      toggleAnimation(openedPanel, showMoreButton, openedContent, false);
      if (openedPanel === panel) {
        return;
      }
    }

    toggleAnimation(panel, panelButton, panelContent, open);
  }

  function toggleAnimation(panel, panelButton, panelContent, open) {
    panelButton.animation.reversed(!open);
    panelButton.classList.toggle('button--showing');
    panel.classList.toggle('accordion-panel--expanded');
    panel.setAttribute('aria-expanded', open ? 'true' : 'false');
    panelContent.setAttribute('aria-hidden', open ? 'false' : 'true');
    panelContent.classList.toggle('expanded');
  }

  function createAnimation(element) {
    const panelButton = element.querySelector('.accordion-panel__button');
    const panelContent = element.querySelector('.accordion-panel__content');

    setTimeout(() => {
      gsap.set(panelContent, { height: 'auto' });

      const tween = gsap.from(panelContent, {
        height: 0,
        duration: 0.3,
        ease: 'power2.out',
        reversed: true
      });

      panelButton.animation = tween;
      animations.push(tween);
    }, 20);
  }

  // expandable text
  const expandTextButtons = document.querySelectorAll('.expand-text');
  if (expandTextButtons.length > 0) {
    expandTextButtons.forEach(button => {
      const parent = button.parentNode;
      const text = parent.querySelector('.expandable-text');
      button.addEventListener('click', () => {
        const expandedText = document.querySelector('.expanded-text');
        if (expandedText) {
          const expandedButton = expandedText.parentNode.querySelector('.expand-text');
          gsap.to(expandedText, {
            height: '44px',
            duration: 0.5,
            onComplete: () => {
              expandedText.classList.remove('expanded-text');
            }
          });
          expandedButton.classList.remove('button--showing');
          if (expandedText === text) {
            return;
          }
        }
        text.classList.add('expanded-text');
        gsap.to(text, { height: 'auto', duration: 0.5 });
        button.classList.add('button--showing');
      });
    });
  }
})();
