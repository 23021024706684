import { startLines, clearLines } from './lineMarks';
import { clearRectangles, startRectangles } from './rectangles';

export const clearScalability = () => {
  clearLines();
  clearRectangles();
};

export const startScalability = () => {
  startRectangles();
  startLines();
};
