function startCardAnimation() {
  // currently works fine for all screen sizes
  // todo, if in the future styles change, it is possible card.width will need to be recomputed on "resize"

  const PIXELS_PER_SECOND = 50;

  function getTotalWidthWithMargins(element) {

    // for smooth animation every card is rendered twice, here we calculate number of orginal cards (before duplication)
    const numberOfOriginalChildren = (element.children.length / 2);

    let total = 0;
    //goes through original (first half) cards and adds all widths
    for (const child in element.children) {
      if (Number(child) >= numberOfOriginalChildren) break;

      const childElement = element.children[Number(child)];
      const styles = window.getComputedStyle(childElement);
      const margin = parseFloat(styles['marginLeft']) +
        parseFloat(styles['marginRight']);

      // Adds width of element and width of margins to total width
      total += childElement.offsetWidth + margin;
    }

    return total;
  }

  function getAllGroupsOfAnimatedRows() {
    const groups = document.querySelectorAll('.carousel__wrapper');
    let groupsOfRows = Array.from(groups).map((group) => {
      let rows = [];
      for (let index = 0; index < group.children.length; index++) {
        const isMovingLeft = index % 2 === 0;

        rows.push({
          element: group.children[index],
          direction: isMovingLeft ? 1 : -1,
        });
      }
      return rows;
    });
    return groupsOfRows;
  }

  const groupsOfRows = getAllGroupsOfAnimatedRows();

  function animateCards() {
    const styleEl = document.createElement('style');
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;

    for (const rowGroup of groupsOfRows) {
      for (const row of rowGroup) {
        const { element } = row;
        const elementWidth = getTotalWidthWithMargins(element);
        const animationLength = elementWidth / PIXELS_PER_SECOND;

        element.style = `
          animation-duration: ${animationLength}s;
          animation-name: carousel-animation__${element.id};
        `;

        const isMovingLeft = row.direction === 1;
        const maxLeftOffset = `-${elementWidth}px`;
        const startOffset = isMovingLeft ? 0 : maxLeftOffset;
        const endOffset = isMovingLeft ? maxLeftOffset : 0;

        styleSheet.insertRule(`
          @keyframes carousel-animation__${element.id} {
            0% {
              transform: translateX(${startOffset})
            }
            100% {
              transform: translateX(${endOffset})
            }
          }
        `, styleSheet.cssRules.length);

      }
    }
  }
  setTimeout(animateCards, 500); // todo - at inital load this computes wrong sizes of rows, this needs to run when DOM sets up properly
}

startCardAnimation();
