//Press release page elements
const pressBtn = document.querySelector('#load-more-btn');
const hiddenPressList = [...document.querySelectorAll('.press-releases__wrapper.hidden')];
const hiddenPressWrapper = '.press-releases__wrapper.hidden';
const increasePress = 3;
let loadPress = 3;

//Blog page elements
const blogsBtn = document.querySelector('#load-more-blogs');
const hiddenBlogsList = [...document.querySelectorAll('.blog-article__wrapper.hidden')]
const hiddenBlogWrapper = '.blog-article__wrapper.hidden';
const increaseBlogs = 5;
let loadBlogs = 5;


function loadMoreButton(button, list, loadBy, increaseBy, wrapper){
    if(button) {
        button.addEventListener('click', () => {
            list.forEach( function(element, index) {
                if (index < loadBy){
                    element.classList.remove('hidden');
                }
            });

            loadBy += increaseBy

            button.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            });

            if ([...document.querySelectorAll(wrapper)].length === 0){
                button.classList.add('hidden');
            };
        });
    }
};

loadMoreButton(pressBtn, hiddenPressList, loadPress, increasePress, hiddenPressWrapper);
loadMoreButton(blogsBtn, hiddenBlogsList, loadBlogs, increaseBlogs, hiddenBlogWrapper);