import { init } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

init({
  dsn: 'https://90c2fe835e6544e490e022e5d39ce77d@o1070812.ingest.sentry.io/6067179',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  // eslint-disable-next-line no-undef
  environment: ENVIRONMENT,
  denyUrls: [
    /localhost:8080\//i,
    /extensions\//i,
    /^chrome:\/\//i,
    /^(chrome|safari)-extension:\/\//i,
  ],
});
