let banners = document.querySelectorAll('[data-banner]');
for (let banner of banners) {
  let bannerId = banner.id;
  if (!window.localStorage) {
    banner.classList.remove('hide-on-loading');
  }
  if (shouldHideBanner(banner)) {
    banner.remove();
  } else {
    banner.classList.remove('hide-on-loading');
    window.localStorage.removeItem(bannerId);
  }
}

let hideBannerButtons = document.querySelectorAll('[data-hide-banner]');
for (let button of hideBannerButtons) {
  let { bannerId, bannerExpiry } = button.dataset;
  button.addEventListener('click', () => {
    let banner = document.getElementById(bannerId);
    banner.remove();
    let bannerData = {
      expirationDate: bannerExpiry
    };
    if (window.localStorage) {
      window.localStorage.setItem(bannerId, JSON.stringify(bannerData));
    }
    setContentTopPosition();
  });
}

function shouldHideBanner(banner) {
  let { bannerStart, bannerExpiry } = banner.dataset;
  let currentDate = new Date();
  let bannerId = banner.id;
  let bannerData = JSON.parse(localStorage.getItem(bannerId));
  if (bannerData) {
    if ((new Date).getTime() > new Date(bannerData.expirationDate)) {
      window.localStorage.removeItem(bannerId);
    }
    return (new Date).getTime() < new Date(bannerData.expirationDate);
  }
  return new Date(bannerStart) > currentDate || new Date(bannerExpiry) < currentDate;
}

let topBanner = document.getElementById('top-banner');
let header = document.getElementById('header');
let mainContent = document.getElementById('main-content');

function setContentTopPosition() {
  if (topBanner) {
    header.style['top'] = `${topBanner.offsetHeight}px`;
    mainContent.style['top'] = `${topBanner.offsetHeight}px`;
  }
}
setContentTopPosition();
