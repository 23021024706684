import tabsAnimations from './animations/tabsAnimations';

const tabsList = document.querySelector('.tabs__list');
if (tabsList) {
  const tabs = tabsList.querySelectorAll('.tabs__list a');
  const tabsContainer = document.querySelector('.tabs__container');

  let previousAnimation = '';
  tabs.forEach(function (tab) {
    tab.addEventListener('click', function (event) {
      event.preventDefault();

      let target = tab.getAttribute('href').substring(1);
      if (tab.className.includes('active')) {
        return;
      }
      let currentTab = tabsList.querySelector('.active');
      currentTab.classList.remove('active');

      let currentTabContainer = tabsContainer.querySelector('.active');
      currentTabContainer.classList.remove('active');

      let newTabContainer = document.getElementById(target);
      newTabContainer.classList.add('active');
      tab.classList.add('active');


      if (previousAnimation !== '') {
        tabsAnimations[previousAnimation].clear();
      }

      if (tab.dataset.animation) {
        let animation = tab.dataset.animation;
        tabsAnimations[animation].start();
        previousAnimation = animation;
      }

    }, false);
  });
}
