import { captureException } from '@sentry/browser';

var _paq = window._paq = window._paq || [];

let clickEvents = document.querySelectorAll('[data-event-action=click]');
clickEvents.forEach(item => {
  let { eventCategory, eventAction, eventName } = item.dataset;
  item.addEventListener('click', () => {
    try {
      _paq.push(['trackEvent', eventCategory, eventAction, eventName]);
    } catch (err) {
      captureException(err);
    }
  });
});
