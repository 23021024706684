import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
gsap.registerPlugin(MotionPathPlugin);

//Definingmtimeline
const lockBodyAnim = gsap.timeline({
  repeat: -1,
  yoyo: true,
});

lockBodyAnim.set('.lock-body-grad stop', { stopColor: '#EED7EF' });

//Lock-body animation timeline
lockBodyAnim.to('.lock-body-grad stop', {
  stopColor: gsap.utils.wrap(['#CFACD1', '#C2ACD4', '#A1AEDE', '#A1AEDE', '#EED7EF']),
  duration: 1.5
})
  .to('.lock-body-grad stop', {
    stopColor: gsap.utils.wrap(['#A1AEDE', '#EED7EF', '#CFACD1', '#C2ACD4', '#A1AEDE']),
    duration: 1.5
  })
  .to('.lock-body-grad stop', {
    stopColor: gsap.utils.wrap(['#C2ACD4', '#A1AEDE', '#A1AEDE', '#EED7EF', '#CFACD1']),
    duration: 1.5
  });

lockBodyAnim.pause();

export const clearBody = () => {
  lockBodyAnim.time(0);
  lockBodyAnim.pause();
};

export const startBody = () => {
  lockBodyAnim.restart();
};
