import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
gsap.registerPlugin(MotionPathPlugin);

gsap.timeline({
  repeat: -1,
  yoyo: true,
  defaults: {
    duration: 1.8
  }
})
  .add('start')
  .add('delay')
  .add('later')
  .to('.chart-rectangle',
    {
      scaleY: 1.6,
      transformOrigin: 'right bottom'
    },
    'start')
  .to('.chart-rectangle-2',
    {
      scaleY: 0.5,
      transformOrigin: 'right bottom'
    },
    'delay')
  .to('.chart-rectangle-3',
    {
      scaleY: 1.8,
      transformOrigin: 'right bottom'
    },
    'later')
  .to('.chart-rectangle-4',
    {
      scaleY: 0.2,
      transformOrigin: 'right bottom'
    },
    'delay')
  .to('.chart-rectangle-5',
    {
      scaleY: 2,
      transformOrigin: 'right bottom'
    },
    'later')
  .to('.chart-rectangle-6',
    {
      scaleY: 0.3,
      transformOrigin: 'right bottom'
    },
    'start')
  .to('.chart-rectangle-7',
    {
      scaleY: 1.5,
      transformOrigin: 'right bottom'
    },
    'later')
  .to('.chart-rectangle-8',
    {
      scaleY: 0.2,
      transformOrigin: 'right bottom'
    },
    'delay');
