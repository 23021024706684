import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
gsap.registerPlugin(MotionPathPlugin);

//Animation defined
const lineAnim = gsap.timeline({
  //paused:true,
  repeat: 0
});

lineAnim.set('.line-grad-0 stop, .line-grad-1 stop, .line-grad-2 stop, .line-grad-3 stop, .line-grad-4 stop, .line-grad-5 stop', { stopColor: '#EED7EF' });

//Gradient <stop> color transitions defined
const startColor = gsap.utils.wrap(['#D0AED2', '#D0AED2', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A']);

const firstColor = gsap.utils.wrap(['#302D4A', '#D0AED2', '#D0AED2', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A']);

const secondColor = gsap.utils.wrap(['#302D4A', '#302D4A', '#D0AED2', '#D0AED2', '#302D4A', '#302D4A', '#302D4A', '#302D4A']);

const thirdColor = gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#D0AED2', '#D0AED2', '#302D4A', '#302D4A', '#302D4A']);

const fourthColor = gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#302D4A', '#D0AED2', '#D0AED2', '#302D4A', '#302D4A']);

const fifthColor = gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#D0AED2', '#D0AED2', '#302D4A']);

const sixthColor = gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#D0AED2', '#D0AED2']);

const seventhColor = gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#D0AED2']);

const endColor = gsap.utils.wrap(['#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A', '#302D4A']);


//Timeline Animation of Line Gradients
//Line 0
lineAnim.add('start')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: startColor,
    duration: 0.4
  }, 'start')
  .add('one')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: firstColor,
    duration: 0.4
  }, 'one')
  .add('two')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: secondColor,
    duration: 0.4
  }, 'two')
  .add('three')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: thirdColor,
    duration: 0.4
  }, 'three')
  .add('four')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: fourthColor,
    duration: 0.4
  }, 'four')
  .add('five')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: fifthColor,
    duration: 0.4
  }, 'five')
  .add('six')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: sixthColor,
    duration: 0.4
  }, 'six')
  .add('seven')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: seventhColor,
    duration: 0.4
  }, 'seven')
  .add('end')
  .to('.line-grad-0 stop', {
    x: 132,
    y: 161,
    stopColor: endColor,
    duration: 0.4
  }, 'end')


  // Line 1
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: startColor,
    duration: 0.4
  }, 'start')
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: firstColor,
    duration: 0.4
  }, 'one')
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: secondColor,
    duration: 0.4
  }, 'two')
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: thirdColor,
    duration: 0.4
  }, 'three')
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: fourthColor,
    duration: 0.4
  }, 'four')
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: fifthColor,
    duration: 0.4
  }, 'five')
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: sixthColor,
    duration: 0.4
  }, 'six')
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: seventhColor,
    duration: 0.4
  }, 'seven')
  .to('.line-grad-1 stop', {
    x: 271.263,
    y: 187.763,
    stopColor: endColor,
    duration: 0.4
  }, 'end')


  // Line 2
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: startColor,
    duration: 0.4
  }, 'start')
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: firstColor,
    duration: 0.4
  }, 'one')
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: secondColor,
    duration: 0.4
  }, 'two')
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: thirdColor,
    duration: 0.4
  }, 'three')
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: fourthColor,
    duration: 0.4
  }, 'four')
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: fifthColor,
    duration: 0.4
  }, 'five')
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: sixthColor,
    duration: 0.4
  }, 'six')
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: seventhColor,
    duration: 0.4
  }, 'seven')
  .to('.line-grad-2 stop', {
    x: 317,
    y: 1.88191e+07,
    stopColor: endColor,
    duration: 0.4
  }, 'end')


  // Line 3
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: startColor,
    duration: 0.4
  }, 'start')
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: firstColor,
    duration: 0.4
  }, 'one')
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: secondColor,
    duration: 0.4
  }, 'two')
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: thirdColor,
    duration: 0.4
  }, 'three')
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: fourthColor,
    duration: 0.4
  }, 'four')
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: fifthColor,
    duration: 0.4
  }, 'five')
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: sixthColor,
    duration: 0.4
  }, 'six')
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: seventhColor,
    duration: 0.4
  }, 'seven')
  .to('.line-grad-3 stop', {
    x: 102.994,
    y: -2048,
    stopColor: endColor,
    duration: 0.4
  }, 'end')


  // Line 4
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: startColor,
    duration: 0.4
  }, 'start')
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: firstColor,
    duration: 0.4
  }, 'one')
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: secondColor,
    duration: 0.4
  }, 'two')
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: thirdColor,
    duration: 0.4
  }, 'three')
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: fourthColor,
    duration: 0.4
  }, 'four')
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: fifthColor,
    duration: 0.4
  }, 'five')
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: sixthColor,
    duration: 0.4
  }, 'six')
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: seventhColor,
    duration: 0.4
  }, 'seven')
  .to('.line-grad-4 stop', {
    x: 210,
    y: 356,
    stopColor: endColor,
    duration: 0.4
  }, 'end')


  // Line 5
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: startColor,
    duration: 0.4
  }, 'start')
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: firstColor,
    duration: 0.4
  }, 'one')
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: secondColor,
    duration: 0.4
  }, 'two')
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: thirdColor,
    duration: 0.4
  }, 'three')
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: fourthColor,
    duration: 0.4
  }, 'four')
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: fifthColor,
    duration: 0.4
  }, 'five')
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: sixthColor,
    duration: 0.4
  }, 'six')
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: seventhColor,
    duration: 0.4
  }, 'seven')
  .to('.line-grad-5 stop', {
    x: 208.75,
    y: 108,
    stopColor: endColor,
    duration: 0.4
  }, 'end');

lineAnim.pause();

export const clearLines = () => {
  lineAnim.time(0);
  lineAnim.pause();
};

export const startLines = () => {
  lineAnim.restart();
};
